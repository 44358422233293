import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0ca141e5 = () => interopDefault(import('../pages/absen/index.vue' /* webpackChunkName: "pages/absen/index" */))
const _26370580 = () => interopDefault(import('../pages/bahan/index.vue' /* webpackChunkName: "pages/bahan/index" */))
const _43b5aef0 = () => interopDefault(import('../pages/bank/index.vue' /* webpackChunkName: "pages/bank/index" */))
const _cfc3edc4 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _1031092a = () => interopDefault(import('../pages/draft/index.vue' /* webpackChunkName: "pages/draft/index" */))
const _a117312a = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _9a302b1a = () => interopDefault(import('../pages/index_catalog.vue' /* webpackChunkName: "pages/index_catalog" */))
const _3aeef081 = () => interopDefault(import('../pages/jadwal/index.vue' /* webpackChunkName: "pages/jadwal/index" */))
const _1faeff50 = () => interopDefault(import('../pages/kasir/index.vue' /* webpackChunkName: "pages/kasir/index" */))
const _106f5c2b = () => interopDefault(import('../pages/katalog/index.vue' /* webpackChunkName: "pages/katalog/index" */))
const _d58b62ec = () => interopDefault(import('../pages/konfigurasi/index.vue' /* webpackChunkName: "pages/konfigurasi/index" */))
const _719f05ff = () => interopDefault(import('../pages/kunjungan/index.vue' /* webpackChunkName: "pages/kunjungan/index" */))
const _dd2ed216 = () => interopDefault(import('../pages/laporan/index.vue' /* webpackChunkName: "pages/laporan/index" */))
const _b41b9af0 = () => interopDefault(import('../pages/layanan/index.vue' /* webpackChunkName: "pages/layanan/index" */))
const _0bbc3903 = () => interopDefault(import('../pages/multi/index.vue' /* webpackChunkName: "pages/multi/index" */))
const _66f5a87b = () => interopDefault(import('../pages/note.vue' /* webpackChunkName: "pages/note" */))
const _375aa425 = () => interopDefault(import('../pages/panduan/index.vue' /* webpackChunkName: "pages/panduan/index" */))
const _04750c60 = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _d0d1338c = () => interopDefault(import('../pages/pegawai/index.vue' /* webpackChunkName: "pages/pegawai/index" */))
const _09604e4a = () => interopDefault(import('../pages/pelanggan/index.vue' /* webpackChunkName: "pages/pelanggan/index" */))
const _a7bd80a8 = () => interopDefault(import('../pages/pembayaran/index.vue' /* webpackChunkName: "pages/pembayaran/index" */))
const _06ae1ab5 = () => interopDefault(import('../pages/pengajuan/index.vue' /* webpackChunkName: "pages/pengajuan/index" */))
const _01bf4ec5 = () => interopDefault(import('../pages/penjadwalan/index.vue' /* webpackChunkName: "pages/penjadwalan/index" */))
const _c3c697a6 = () => interopDefault(import('../pages/pinjam/index.vue' /* webpackChunkName: "pages/pinjam/index" */))
const _fc92ce0e = () => interopDefault(import('../pages/produk/index.vue' /* webpackChunkName: "pages/produk/index" */))
const _d164cf70 = () => interopDefault(import('../pages/profil/index.vue' /* webpackChunkName: "pages/profil/index" */))
const _c07a0dd6 = () => interopDefault(import('../pages/riwayat/index.vue' /* webpackChunkName: "pages/riwayat/index" */))
const _561a3a00 = () => interopDefault(import('../pages/rutin/index.vue' /* webpackChunkName: "pages/rutin/index" */))
const _f8625e9a = () => interopDefault(import('../pages/saldo/index.vue' /* webpackChunkName: "pages/saldo/index" */))
const _459dede8 = () => interopDefault(import('../pages/shift/index.vue' /* webpackChunkName: "pages/shift/index" */))
const _627b583d = () => interopDefault(import('../pages/titipan/index.vue' /* webpackChunkName: "pages/titipan/index" */))
const _07658bab = () => interopDefault(import('../pages/toko/index.vue' /* webpackChunkName: "pages/toko/index" */))
const _06b3a79c = () => interopDefault(import('../pages/transaksi/index.vue' /* webpackChunkName: "pages/transaksi/index" */))
const _55e35a2a = () => interopDefault(import('../pages/auth/check.vue' /* webpackChunkName: "pages/auth/check" */))
const _75d896f1 = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _50b167ea = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _30014525 = () => interopDefault(import('../pages/auth/success.vue' /* webpackChunkName: "pages/auth/success" */))
const _cafc6964 = () => interopDefault(import('../pages/jadwal/desktop/index.vue' /* webpackChunkName: "pages/jadwal/desktop/index" */))
const _a06c195c = () => interopDefault(import('../pages/jadwal/new/index.vue' /* webpackChunkName: "pages/jadwal/new/index" */))
const _5169ca4d = () => interopDefault(import('../pages/jadwal/tambah/index.vue' /* webpackChunkName: "pages/jadwal/tambah/index" */))
const _3d5719d0 = () => interopDefault(import('../pages/kunjungan/new/index.vue' /* webpackChunkName: "pages/kunjungan/new/index" */))
const _820ce0d4 = () => interopDefault(import('../pages/penjadwalan/new/index.vue' /* webpackChunkName: "pages/penjadwalan/new/index" */))
const _cdcd25c2 = () => interopDefault(import('../pages/jadwal/new/desktop/index.vue' /* webpackChunkName: "pages/jadwal/new/desktop/index" */))
const _515e2a5c = () => interopDefault(import('../pages/jadwal/new/tambah/index.vue' /* webpackChunkName: "pages/jadwal/new/tambah/index" */))
const _82950022 = () => interopDefault(import('../pages/jadwal/new/desktop/tambah/index.vue' /* webpackChunkName: "pages/jadwal/new/desktop/tambah/index" */))
const _3fd31cce = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0113a8e2 = () => interopDefault(import('../pages/jadwal/new/desktop/ubah/_id.vue' /* webpackChunkName: "pages/jadwal/new/desktop/ubah/_id" */))
const _696491cf = () => interopDefault(import('../pages/jadwal/new/ubah/_id.vue' /* webpackChunkName: "pages/jadwal/new/ubah/_id" */))
const _e1f75c0a = () => interopDefault(import('../pages/transaksi/payment/multi/_id.vue' /* webpackChunkName: "pages/transaksi/payment/multi/_id" */))
const _013fa578 = () => interopDefault(import('../pages/transaksi/payment/pembayaran/_id.vue' /* webpackChunkName: "pages/transaksi/payment/pembayaran/_id" */))
const _75a57928 = () => interopDefault(import('../pages/auth/reset/_slug.vue' /* webpackChunkName: "pages/auth/reset/_slug" */))
const _65904b66 = () => interopDefault(import('../pages/invoice/print/_slug.vue' /* webpackChunkName: "pages/invoice/print/_slug" */))
const _62505b80 = () => interopDefault(import('../pages/jadwal/ubah/_id.vue' /* webpackChunkName: "pages/jadwal/ubah/_id" */))
const _0afd5411 = () => interopDefault(import('../pages/transaksi/payment/_id.vue' /* webpackChunkName: "pages/transaksi/payment/_id" */))
const _b3eab66e = () => interopDefault(import('../pages/feedback/_slug.vue' /* webpackChunkName: "pages/feedback/_slug" */))
const _c4023622 = () => interopDefault(import('../pages/invoice/_slug.vue' /* webpackChunkName: "pages/invoice/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/absen",
    component: _0ca141e5,
    name: "absen"
  }, {
    path: "/bahan",
    component: _26370580,
    name: "bahan"
  }, {
    path: "/bank",
    component: _43b5aef0,
    name: "bank"
  }, {
    path: "/dashboard",
    component: _cfc3edc4,
    name: "dashboard"
  }, {
    path: "/draft",
    component: _1031092a,
    name: "draft"
  }, {
    path: "/home",
    component: _a117312a,
    name: "home"
  }, {
    path: "/index_catalog",
    component: _9a302b1a,
    name: "index_catalog"
  }, {
    path: "/jadwal",
    component: _3aeef081,
    name: "jadwal"
  }, {
    path: "/kasir",
    component: _1faeff50,
    name: "kasir"
  }, {
    path: "/katalog",
    component: _106f5c2b,
    name: "katalog"
  }, {
    path: "/konfigurasi",
    component: _d58b62ec,
    name: "konfigurasi"
  }, {
    path: "/kunjungan",
    component: _719f05ff,
    name: "kunjungan"
  }, {
    path: "/laporan",
    component: _dd2ed216,
    name: "laporan"
  }, {
    path: "/layanan",
    component: _b41b9af0,
    name: "layanan"
  }, {
    path: "/multi",
    component: _0bbc3903,
    name: "multi"
  }, {
    path: "/note",
    component: _66f5a87b,
    name: "note"
  }, {
    path: "/panduan",
    component: _375aa425,
    name: "panduan"
  }, {
    path: "/payment",
    component: _04750c60,
    name: "payment"
  }, {
    path: "/pegawai",
    component: _d0d1338c,
    name: "pegawai"
  }, {
    path: "/pelanggan",
    component: _09604e4a,
    name: "pelanggan"
  }, {
    path: "/pembayaran",
    component: _a7bd80a8,
    name: "pembayaran"
  }, {
    path: "/pengajuan",
    component: _06ae1ab5,
    name: "pengajuan"
  }, {
    path: "/penjadwalan",
    component: _01bf4ec5,
    name: "penjadwalan"
  }, {
    path: "/pinjam",
    component: _c3c697a6,
    name: "pinjam"
  }, {
    path: "/produk",
    component: _fc92ce0e,
    name: "produk"
  }, {
    path: "/profil",
    component: _d164cf70,
    name: "profil"
  }, {
    path: "/riwayat",
    component: _c07a0dd6,
    name: "riwayat"
  }, {
    path: "/rutin",
    component: _561a3a00,
    name: "rutin"
  }, {
    path: "/saldo",
    component: _f8625e9a,
    name: "saldo"
  }, {
    path: "/shift",
    component: _459dede8,
    name: "shift"
  }, {
    path: "/titipan",
    component: _627b583d,
    name: "titipan"
  }, {
    path: "/toko",
    component: _07658bab,
    name: "toko"
  }, {
    path: "/transaksi",
    component: _06b3a79c,
    name: "transaksi"
  }, {
    path: "/auth/check",
    component: _55e35a2a,
    name: "auth-check"
  }, {
    path: "/auth/forgot",
    component: _75d896f1,
    name: "auth-forgot"
  }, {
    path: "/auth/login",
    component: _50b167ea,
    name: "auth-login"
  }, {
    path: "/auth/success",
    component: _30014525,
    name: "auth-success"
  }, {
    path: "/jadwal/desktop",
    component: _cafc6964,
    name: "jadwal-desktop"
  }, {
    path: "/jadwal/new",
    component: _a06c195c,
    name: "jadwal-new"
  }, {
    path: "/jadwal/tambah",
    component: _5169ca4d,
    name: "jadwal-tambah"
  }, {
    path: "/kunjungan/new",
    component: _3d5719d0,
    name: "kunjungan-new"
  }, {
    path: "/penjadwalan/new",
    component: _820ce0d4,
    name: "penjadwalan-new"
  }, {
    path: "/jadwal/new/desktop",
    component: _cdcd25c2,
    name: "jadwal-new-desktop"
  }, {
    path: "/jadwal/new/tambah",
    component: _515e2a5c,
    name: "jadwal-new-tambah"
  }, {
    path: "/jadwal/new/desktop/tambah",
    component: _82950022,
    name: "jadwal-new-desktop-tambah"
  }, {
    path: "/",
    component: _3fd31cce,
    name: "index"
  }, {
    path: "/jadwal/new/desktop/ubah/:id?",
    component: _0113a8e2,
    name: "jadwal-new-desktop-ubah-id"
  }, {
    path: "/jadwal/new/ubah/:id?",
    component: _696491cf,
    name: "jadwal-new-ubah-id"
  }, {
    path: "/transaksi/payment/multi/:id?",
    component: _e1f75c0a,
    name: "transaksi-payment-multi-id"
  }, {
    path: "/transaksi/payment/pembayaran/:id?",
    component: _013fa578,
    name: "transaksi-payment-pembayaran-id"
  }, {
    path: "/auth/reset/:slug?",
    component: _75a57928,
    name: "auth-reset-slug"
  }, {
    path: "/invoice/print/:slug?",
    component: _65904b66,
    name: "invoice-print-slug"
  }, {
    path: "/jadwal/ubah/:id?",
    component: _62505b80,
    name: "jadwal-ubah-id"
  }, {
    path: "/transaksi/payment/:id?",
    component: _0afd5411,
    name: "transaksi-payment-id"
  }, {
    path: "/feedback/:slug?",
    component: _b3eab66e,
    name: "feedback-slug"
  }, {
    path: "/invoice/:slug?",
    component: _c4023622,
    name: "invoice-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
